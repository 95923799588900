import React, { forwardRef, useRef, useEffect, useState } from "react";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import ReactPlayer from "react-player";

const Overlay = forwardRef(({ caption, scroll }, ref) => {
  const secFiveRef = useRef();
  const [myElementIsVisible, setMyElementIsVisible] = useState();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setMyElementIsVisible(entry.isIntersecting);
    }, options);
    observer.observe(secFiveRef.current);
  }, []);

  return (
    <>
      <div
        ref={ref}
        onScroll={(e) => {
          scroll.current =
            e.target.scrollTop / (e.target.scrollHeight - window.innerHeight);
          caption.current.innerText = scroll.current.toFixed(2);
        }}
        className="scroll"
      >
        <div style={{ height: "400vh" }}>
          <div className="w-full h-full flex flex-col justify-between items-center">
            <div className="w-full md:w-2/3 lg:w-2/3 h-1/3 w-full flex flex-wrap px-8 md:px-24 justify-center">
              <div
                style={{ mixBlendMode: "screen" }}
                className="w-full flex items-center justify-center flex flex-wrap"
              >
                <img
                  className="p-6 max-w-lg w-full h-auto object-scale-down"
                  src="logo-river.png"
                />

                <div className="w-full h-24 flex flex-col -mt-80">
                  <div className="w-full mb-4 flex items-center justify-center">
                    <a
                      target="_blank"
                      href="https://www.instagram.com/nilo.river/"
                    >
                      <img className="w-8 h-8 mr-2" src="instagram.png" />
                    </a>
                    <a target="_blank" href="https://vimeo.com/user52627308">
                      <img className="w-8 h-8 " src="vimeo.png" />
                    </a>
                  </div>
                  <div className="text-center text-sm w-full text-white">
                    Nilo River | Creative Developer
                  </div>
                </div>
              </div>
              {/* <div className="w-1/2">
            <img
              className="max-w-64 p-6 w-full h-full -mt-64 object-scale-down"
              src="logo-river.png"
            />
          </div> */}
            </div>

            <div className="h-80 w-full flex flex-row flex-wrap">
              <div className="h-full w-full md:w-1/3 flex items-center justify-end pr-10">
                <div
                  style={{ width: 200, height: 200 }}
                  className="rounder rounded-lg bg-black"
                >
                  <ReactPlayer
                    loop
                    playing
                    playsinline
                    muted
                    width="200px"
                    height="200px"
                    url={[
                      { src: "metaverso-demo-rigg.mp4", type: "video/mp4" },
                      // {src: 'foo.ogg', type: 'video/ogg'}
                    ]}
                  />
                </div>
              </div>
              <div className="h-full w-full md:w-2/3 flex items-center justify-start">
                <div className="dot " style={{ padding: 20 }}>
                  <h1>creative developer</h1>
                  Desenvolvo experiências interativas que despertam o interesse
                  do usuário e estimulam a curiosidade baseadas em tecnologias
                  que compreendem o contexto e possibilitam máxima imersão.
                </div>
              </div>
            </div>

            <div className=" h-24 w-full" />
          </div>
        </div>
        <div ref={secFiveRef} style={{ height: "200vh" }}>
          <div className="dot">
            <BrowserView className="dotHeader" style={{ padding: 0 }}>
              {myElementIsVisible ? <h1>sem limites</h1> : <h1>sem limites</h1>}
            </BrowserView>
            <MobileView className="dotHeader" style={{ padding: 0 }}>
              <h1>sem limites</h1>
            </MobileView>
            Como <strong>Desenvolvedor Criativo</strong> meu papel é reunir e
            preparar as peças necessárias para alcançar o objetivo de
            experiências com interação humano-máquina, sempre fugindo das
            limitações de frameworks e soluções de prateleira.
            <p className="mt-4">
              Talvez você já tenha se frustrado quando tentou atualizar seu Site
              ou App e percebeu que o Dev criou blocão difícil de personalizar e
              escalar. <br />
              Assim como eu, você deve ficar insatisfeito com Apps e Sites todos
              tão parecidos.
            </p>
            <p className="mt-4">
              Meu diferencial é tentar ir além. Não me contento com o mesmo. Não
              me amarro em nenhuma linguagem ou framework. Meu objetivo é fluir
              entre componentes com foco na melhor entrega.
            </p>
          </div>
        </div>
        <div style={{ height: "200vh" }}>
          <div className="dot">
            <h1>em busca do flow</h1>O que me motiva e ativa meu{" "}
            <strong>estado de flow</strong> são projetos com propósito.
            <br />
            Se você tem uma ideia ou projeto que pode causar um impacto através
            da tecnologia e da arte eu vou adorar colaborar.
            <p className="mt-4">
              Comigo não se trata apenas de utilizar a última tecnologia
              disponível. <br />
              Trabalhar em um projeto original e com propósito é o que vai
              oxigenar a descoberta da melhor solução.
            </p>
          </div>
        </div>
        <div style={{ height: "200vh" }}>
          <div className="dot">
            <h1>atitude multidisciplinar</h1>
            A arte de criar sistemas e resolver problemas através de código tem
            sido uma tarefa intensa em meus 15+ anos de experiência. <br />
            <p className="mt-4">
              Já me envolvi com sistemas desde a <strong>Infra</strong> física,
              até <strong>Interface com usuário</strong>, passando por{" "}
              <strong>Apps e Games</strong> até instalações artísticas. <br />
              Meu prazer está no desafio de entender e combinar componentes para
              atingir resultados cada vez interessantes.
            </p>
          </div>
        </div>
        <div style={{ height: "200vh" }}>
          <div className="dot">
            <h1>AR / VR / XR </h1>Entre o mundo físico e o virtual o que
            transcende é arte e comunicação de ideias. <br />
            <p className="mt-2">
              Vejo no <strong>Metaverso</strong> a oportunidade de novas
              narrativas e/ou modos de contar estórias. <br />
              Talvez muitos roteiros que você já tenha imaginado agora encontram
              meios de se "tornarem reais". <br />
            </p>
            <p className="mt-2">
              Preciso de você para criar experiências memoráveis e impactantes e
              uma combinação simbiótica entre Arte / Tech
            </p>
          </div>
        </div>
        <div style={{ height: "200vh" }}>
          <div className="dot">
            <h1>inteligência artificial</h1>Muitos dizem: "Ou dominamos os
            computadores ou eles nos dominarão." ... E de certa forma essa tem
            sido uma grande motivação desde o início dessa jornada que começou
            ainda na infância.
            <p className="mt-4">
              Hoje tenho muita satisfação em atuar em projetos de acessibilidade
              e mobilidade 100% baseados em Inteligência Artificial.
            </p>
          </div>
        </div>
        <div style={{ height: "200vh" }}>
          <div className="dot">
            <h1>só vamos</h1>
            Se assim como eu, você já se deu conta que a tecnologia nunca vai
            parar, então vamos juntos nessa jornada. <br />
            Integrar a tecnologia de uma maneira saudável e capacitiva é tudo
            que podemos fazer para construir um amanhã melhor. <br />
            Não adianta esperar pra ver se o <strong>Blockchain</strong> vai
            virar ou não. Pode parecer assustador, mas eu garanto que ficar pra
            trás será ainda pior. <br />
            <p className="mt-4">
              Será um prazer ouvir sua ideia e colaborar de alguma forma. <br />
              Deixe um{" "}
              <strong>
                <a href="mailto:flow@niloriver.com">email</a>
              </strong>{" "}
              em{" "}
              <strong>
                <a href="mailto:flow@niloriver.com">flow@niloriver.com</a>
              </strong>{" "}
              ou entre em contato comigo nas redes sociais.
            </p>
            <div className="w-full h-24 flex flex-col mt-20">
              <div className="w-full mb-4 flex items-center justify-start">
                <a target="_blank" href="https://www.instagram.com/nilo.river/">
                  <img className="w-8 h-8 mr-2" src="instagram.png" />
                </a>
                <a target="_blank" href="https://vimeo.com/user52627308">
                  <img className="w-8 h-8 " src="vimeo.png" />
                </a>
              </div>
              <div className="text-sm w-full text-white">
                Nilo River | Creative Developer
              </div>
            </div>
          </div>
        </div>
        <span className="caption hidden" ref={caption}>
          0.00
        </span>
      </div>
    </>
  );
});

export default Overlay;
