/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useGLTF, useAnimations, PerspectiveCamera } from "@react-three/drei";

import { useFrame } from "@react-three/fiber";

const color = new THREE.Color();

export default function Model({ statex, scroll, ...props }) {
  const group = useRef();

  const skateboard = useRef();

  const { nodes, materials, animations } = useGLTF("/cena-crud.glb");
  const { actions } = useAnimations(animations, group);
  const [hovered, set] = useState();

  const extras = {
    receiveShadow: true,
    castShadow: true,
    "material-envMapIntensity": 0.2,
  };

  useEffect(() => void (actions["CameraAction.005"].play().paused = true), []);
  useEffect(() => {
    if (hovered)
      group.current.getObjectByName(hovered).material.color.set("white");
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);
  useFrame((state) => {
    actions["CameraAction.005"].time = THREE.MathUtils.lerp(
      actions["CameraAction.005"].time,
      actions["CameraAction.005"].getClip().duration * scroll.current,
      0.05
    );
    group.current.children[0].children.forEach((child, index) => {
      if (child.material) {
        child.material.color.lerp(
          color
            .set(hovered === child.name ? "#3b2174" : "#213274")
            .convertSRGBToLinear(),
          hovered ? 0.1 : 0.05
        );
      }
      const et = state.clock.elapsedTime;
      if (child.name !== "Skateboard") {
        child.position.y = Math.sin((et + index * 2000) / 2) * 1;
        child.rotation.x = Math.sin((et + index * 2000) / 3) / 10;
        child.rotation.y = Math.cos((et + index * 2000) / 2) / 10;
        child.rotation.z = Math.sin((et + index * 2000) / 3) / 10;

        if (child.children && child.children.length > 0) {
          // child.children[0].rotation.z = scroll.current;
          // child.children[0].rotation.y = scroll.current;

          child.children.forEach((childFound) => {
            // childFound.rotation.x = et;

            if (hovered === childFound.name) {
              console.log(
                "COLOR_GOT",
                `#${materials.Skateboard_material.color.getHexString()}`
              );
            }

            childFound?.material?.color.lerp(
              color
                .set(hovered === childFound.name ? "#3b2174" : "#213274")
                .convertSRGBToLinear(),
              hovered ? 0.1 : 0.05
            );
          });

          // console.log("SKATEBOARD", child);
        }
      } else {
        child.position.y = Math.sin((et + index * 2000) / 2) * 1;

        if (child.children && child.children.length > 0) {
          // child.children[0].rotation.z = scroll.current;
          // child.children[0].rotation.y = scroll.current;

          child.children.forEach((childFound) => {
            childFound.rotation.x = et;

            if (hovered === childFound.name) {
              console.log(
                "COLOR_GOT",
                `#${materials.Skateboard_material.color.getHexString()}`
              );
            }

            childFound?.material?.color.lerp(
              color
                .set(hovered === childFound.name ? "#3b2174" : "#213274")
                .convertSRGBToLinear(),
              hovered ? 0.1 : 0.05
            );
          });

          // console.log("SKATEBOARD", child);
        }
      }
    });
  });
  const fragmentShader = `uniform vec3 glowColor;
  varying float intensity;
  void main()
  {
      vec3 glow = glowColor * intensity;
      gl_FragColor = vec4( glow, 1.0 );
  }`;

  const vertexShader = `
  uniform float p;
        varying float intensity;
        void main()
        {
            vec3 vNormal = normalize( normalMatrix * normal );
            intensity = pow(1.0 - abs(dot(vNormal, vec3(0, 0, 1))), p);
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }`;

  console.log("NODES", nodes);
  window.nodx = nodes;

  console.log("MATERIALS", materials);

  console.log("actions", actions);

  const data = useMemo(
    () => ({
      uniforms: {
        p: { type: "f", value: 2 },
        glowColor: { type: "c", value: new THREE.Color(0x84ccff) },
      },
      fragmentShader,
      vertexShader,
      side: THREE.DoubleSide,
      blending: THREE.AdditiveBlending,
      transparent: true,
      depthWrite: false,
    }),
    []
  );

  const { scaleBoard } = statex;

  return (
    <>
      <group ref={group} {...props} dispose={null}>
        <group
          onPointerOver={(e) => (e.stopPropagation(), set(e.object.name))}
          onPointerOut={(e) => (e.stopPropagation(), set(null))}
          position={[0.06, 4.04, 0.35]}
          scale={[0.25, 0.25, 0.25]}
        >
          <mesh
            name="Headphones"
            geometry={nodes.Headphones.geometry}
            material={materials.M_Headphone}
            {...extras}
          />
          <mesh
            name="Notebook"
            geometry={nodes.Notebook.geometry}
            material={materials.M_Notebook}
            {...extras}
          />
          {/* <mesh
            name="Rocket003"
            geometry={nodes.Rocket003.geometry}
            material={materials.M_Rocket}
            {...extras}
          /> */}

          <group
            name="Joystick"
            position={[
              nodes.POSITIONERS.position.x,
              nodes.POSITIONERS.position.y,
              nodes.POSITIONERS.position.z,
            ]}
            scale={[scaleBoard, scaleBoard, scaleBoard]}
            ref={skateboard}
          >
            <mesh
              name="JOYSTICK"
              geometry={nodes.JOYSTICK.geometry}
              material={materials.Joystick_material}
              position={nodes.JOYSTICK_POSITION.position}
              {...extras}
            />
          </group>

          <group
            name="Skateboard"
            position={[
              nodes.POSITIONERS.position.x,
              nodes.POSITIONERS.position.y,
              nodes.POSITIONERS.position.z,
            ]}
            scale={[scaleBoard, scaleBoard, scaleBoard]}
            ref={skateboard}
          >
            <mesh
              name="skateboard"
              geometry={nodes.Skateboard.geometry}
              material={materials.Skateboard_material}
              position={nodes.SKATEBOARD_PARENT.position}
              {...extras}
            />
          </group>

          <mesh
            name="VR_Headset"
            geometry={nodes.VR_Headset.geometry}
            material={materials.M_Headset}
            {...extras}
          />

          <group
            name="Drone"
            position={[
              nodes.POSITIONERS.position.x,
              nodes.POSITIONERS.position.y,
              nodes.POSITIONERS.position.z,
            ]}
            scale={[scaleBoard, scaleBoard, scaleBoard]}
            ref={skateboard}
          >
            <mesh
              name="drone"
              geometry={nodes.DRONE.geometry}
              material={materials.M_Headset}
              position={nodes.DRONE_POSITION.position}
              scale={1}
              {...extras}
            />
          </group>
        </group>
        <group
          name="Camera"
          position={[-1.78, 2.04, 23.58]}
          rotation={[1.62, 0.01, 0.11]}
        >
          <PerspectiveCamera
            makeDefault
            far={100}
            near={0.1}
            fov={28}
            rotation={[-Math.PI / 2, 0, 0]}
          >
            <directionalLight
              castShadow
              position={[10, 20, 15]}
              shadow-camera-right={8}
              shadow-camera-top={8}
              shadow-camera-left={-8}
              shadow-camera-bottom={-8}
              shadow-mapSize-width={1024}
              shadow-mapSize-height={1024}
              intensity={2}
              shadow-bias={-0.0001}
            />

            <ambientLight intensity={0.5} color="white" />
          </PerspectiveCamera>
        </group>
      </group>
    </>
  );
}

useGLTF.preload("/cena-crud.glb");
