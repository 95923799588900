import React, { Suspense, useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Environment } from "@react-three/drei";
import Model from "./Model";
import Overlay from "./Overlay";
import DatGui, { DatColor, DatNumber } from "@tim-soft/react-dat-gui";

export default function App() {
  const overlay = useRef();
  const caption = useRef();
  const scroll = useRef(0);

  const urlParams = new URLSearchParams(window.location.search);
  const debug = urlParams.get("debug"); // johnny

  const [scaleBoard, setScaleBoard] = useState(3);

  const staters = {
    scaleBoard: scaleBoard,
  };

  const handleUpdate = (newData) => {
    console.log("NEW_DATA", newData);
    if (newData.scaleBoard) {
      setScaleBoard(newData.scaleBoard);
    }
  };

  return (
    <>
      <Canvas
        shadows
        onCreated={(state) => state.events.connect(overlay.current)}
        raycaster={{
          computeOffsets: ({ clientX, clientY }) => ({
            offsetX: clientX,
            offsetY: clientY,
          }),
        }}
      >
        <ambientLight intensity={1} />
        <Suspense fallback={null}>
          <Model scroll={scroll} statex={staters} />
          <Environment preset="city" />
        </Suspense>
      </Canvas>
      <Overlay ref={overlay} caption={caption} scroll={scroll} />
      {debug && (
        <DatGui
          data={staters}
          onUpdate={handleUpdate}
          className="react-dat-gui-relative-position"
        >
          <DatNumber
            path="scaleBoard"
            label="scaleBoard"
            min={1}
            max={5}
            step={0.01}
          />
        </DatGui>
      )}
    </>
  );
}
